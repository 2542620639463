import { setUser as SentrySetUser } from '@sentry/browser';
import {
  updateContext as FeatureFlagsUpdateContext,
  addFlagListener,
  removeFlagListener,
  addPublicFlagsLoadedListener,
  removePublicFlagsLoadedListener
} from '../../modules/FeatureFlags.js';
import '@polymer/polymer/polymer-legacy.js';
import '@polymer/font-roboto/roboto.js';
import '@polymer/paper-dialog/paper-dialog.js';
import '@polymer/iron-icon/iron-icon.js';
import '@polymer/iron-icons/iron-icons.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-menu-button/paper-menu-button.js';
import '@polymer/paper-toggle-button/paper-toggle-button.js';
import 'iron-a11y-keys/iron-a11y-keys.js';
import '@polymer/paper-spinner/paper-spinner-lite.js';
import '@polymer/paper-progress/paper-progress.js';
import '@polymer/paper-checkbox/paper-checkbox.js';
import '../style-modules/paper-dialog-style.js';
import '../style-modules/flex.js';
import '../katapult-account-settings/katapult-account-settings.js';
import '../katapult-elements/katapult-button.js';
import '../katapult-status-notifier/katapult-status-notifier.js';
import { KatapultBanner } from '../katapult-elements/katapult-banner.js';
import AppName from '../../app-configuration/appName.js';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
import { PolymerElement } from '@polymer/polymer/polymer-element.js';
import { HowLong } from '../../modules/Date.js';
import { Path } from '../../modules/Path.js';
import { checkPassword } from '../../modules/AuthHelpers.js';
import { setUserInfo as IPLVersionManagerSetUserInfo } from '../../modules/IPLVersionManager.js';

class KatapultAuth extends PolymerElement {
  static get template() {
    return html`
      <style include="paper-dialog-style flex">
        :host {
          font-family: 'Roboto';
        }
        @keyframes fade-up {
          from {
            transform: translateY(24px);
            opacity: 0;
          }
          to {
            transform: none;
            opacity: 1;
          }
        }
        @media screen and (max-width: 600px) {
          #detailsFormContainer {
            flex-direction: column;
          }
          #titleContent {
            text-align: center;
          }
          #descriptionContent {
            display: none;
          }
        }
        :host {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        :host([show-auth-content]:not([show-my-account])) #overlay {
          opacity: 0;
          pointer-events: none;
        }
        ::slotted([awaiting-load]) {
          display: block !important;
        }
        katapult-button {
          margin: 16px 0;
          flex-shrink: 0;
          box-sizing: border-box;
        }
        #overlay {
          z-index: 999;
          position: absolute;
          top: 0;
          left: 0;
          height: inherit;
          width: inherit;
          @apply --katapult-auth-overlay;
          overflow: auto;
          opacity: 1;
          background-color: var(--paper-grey-50);
        }
        #signInWrapper,
        #myAccountWrapper {
          height: 100%;
          width: 100%;
          min-width: fit-content;
          min-height: fit-content;
        }
        #signInWrapper {
          background-color: var(--primary-color);
        }
        #signInContent {
          max-width: 900px;
          padding-bottom: 96px;
        }
        #myAccountContent {
          min-width: 600px;
          max-width: 900px;
          padding: 24px;
          @apply --shadow-elevation-2dp;
          border-radius: 4px;
          background-color: white;
        }
        #myAccountContent > div:not(:last-of-type) {
          margin-bottom: 24px;
        }
        #myAccountContent .title {
          display: block;
          font-weight: bolder;
          font-size: 14pt;
          color: var(--paper-grey-600);
          margin: 4px 0;
        }
        #backgroundImage {
          position: fixed;
          top: 0;
          left: 0;
          height: inherit;
          width: inherit;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          opacity: 0.4;
          pointer-events: none;
        }
        #welcome {
          font-size: 20pt;
          font-weight: lighter;
          text-align: center;
          margin-bottom: 24px;
        }
        #titleContent {
          font-size: 28pt;
          margin-bottom: 24px;
        }
        #form {
          min-width: 330px;
          background-color: white;
          border-radius: 16px;
          padding: 16px;
          box-sizing: border-box;
          color: var(--paper-grey-800);
          overflow: hidden;
          position: relative;
        }
        /* Animation and Delays */
        #welcome,
        #titleContent,
        #descriptionContent {
          padding: 16px;
          color: white;
          animation-name: fade-up;
          animation-duration: 1s;
          animation-fill-mode: forwards;
          opacity: 0;
        }
        #welcome {
          animation-delay: 0.2s;
        }
        #titleContent {
          animation-delay: 0.3s;
        }
        #descriptionContent {
          animation-delay: 0.35s;
        }
        #formContainer {
          animation-delay: 0.4s;
        }
        ::slotted([slot='description-content']) {
          width: 100%;
          font-weight: lighter;
          font-size: 18pt;
          /* color: rgba(225,225,225,0.95); */
          color: var(--paper-grey-300);
        }
        ::slotted([slot='auth-content']) {
          height: 100%;
        }
        ::slotted(paper-toggle-button[slot='preferences-content']) {
          margin-top: 10px;
        }
        .flex {
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
        }
        .flex.column {
          flex-direction: column;
        }
        .flex.left {
          justify-content: flex-start;
        }
        .flex.stretch {
          align-items: stretch;
        }
        .flex.spaceBetween {
          justify-content: space-between;
        }
        .flex.even {
          justify-content: space-evenly;
        }
        .flex.noShrink {
          flex-shrink: 0;
        }
        .flex.center {
          text-align: center;
        }
        #myAccountWrapper katapult-button {
          margin: 16px 0px;
          box-sizing: border-box;
          flex-shrink: 0;
        }
        #form .link {
          margin: 4px;
          padding: 4px;
          text-align: center;
          color: var(--paper-grey-800);
          font-size: 11pt;
        }
        .link:hover {
          cursor: pointer;
        }
        #form p {
          margin: 4px 0;
          text-align: center;
        }
        paper-input {
          --primary-color: var(--secondary-color);
          --paper-input-error: {
            position: relative !important;
            white-space: inherit;
          };
        }
        .message {
          display: flex;
          align-items: center;
          white-space: nowrap;
        }
        .message.success {
          color: var(--paper-green-500);
        }
        .message > iron-icon {
          margin-left: 8px;
        }
        paper-spinner-lite {
          --paper-spinner-color: var(--secondary-color);
        }
        #dbSwitcherButton {
          padding: 0px 0px 0px 5px;
          --paper-menu-button-content: {
            border-radius: 12px;
          };
        }
        #currentDb {
          display: flex;
          justify-content: right;
          padding: 0px 0px 10px 0px;
        }
        #dbSwitcherMenu {
          padding: 4px 8px;
          display: flex;
          align-items: flex-end;
          max-width: 200px;
          align-items: center;
        }
        #dbSwitcherInput {
          margin: 0 4px;
        }
        #loadingSpinner {
          height: 48px;
          width: 48px;
          --paper-spinner-color: white;
          --paper-spinner-stroke-width: 3px;
        }
        #toast {
          display: flex;
          align-items: center;
        }
        katapult-button[iconOnly] {
          margin: 0px;
        }
        [buttons] {
          gap: 8px;
        }
        .password-meter {
          width: 100%;
        }
        .password-meter[value='0'],
        .password-meter[value='1'] {
          --paper-progress-active-color: var(--paper-red-500);
        }
        .password-meter[value='2'] {
          --paper-progress-active-color: var(--paper-orange-500);
        }
        .password-meter[value='3'] {
          --paper-progress-active-color: var(--paper-light-green-500);
        }
        .password-meter[value='4'] {
          --paper-progress-active-color: var(--paper-green-500);
        }
        #emailInput {
          margin-top: 10px;
        }
        .ssoDivider {
          background-color: var(--paper-grey-600);
          flex-grow: 1;
          height: 1px;
          border: 0;
        }
        #ssoButtons {
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin-bottom: 16px;
        }
        .signInButton {
          display: flex;
          flex-direction: column;
        }
        .signInButton katapult-button {
          flex-grow: 1;
          margin: 0;
          justify-content: flex-start; /* TODO (2023-07-17): Change this to use the justify property on katapult-button */
        }
        .signInErrorMessage {
          color: var(--paper-red-500);
          font-size: 14px;
          margin-top: 8px;
        }
        .highlighted-link {
          color: var(--secondary-color);
          text-decoration: none;
          font-weight: bold;
        }
      </style>
      <!-- Polymerfire Elements -->
      <firebase-auth id="auth" provider="password" signed-in="{{signedIn}}" status-known="{{statusKnown}}" user="{{user}}"> </firebase-auth>
      <katapult-firebase-worker path="photoheight/build_numbers" data="{{buildNumbers}}"></katapult-firebase-worker>
      <katapult-firebase-worker
        path="users/[[rootCompany]]/[[user.uid]]"
        loading="{{userRecordLoading}}"
        data="{{userRecord}}"
        disabled="[[!_loadUserData]]"
      >
      </katapult-firebase-worker>
      <katapult-firebase-worker path="user_groups/[[user.uid]]" data="{{_userPermissions}}" disabled="[[!_loadUserData]]" _log>
      </katapult-firebase-worker>
      <katapult-firebase-worker
        path="photoheight/company_space/[[userGroup]]/admins/[[user.uid]]"
        on-change="_companyAdminDataChanged"
        disabled="[[!_loadUserData]]"
      >
      </katapult-firebase-worker>
      <katapult-firebase-worker
        path="photoheight/company_space/[[userGroup]]/prompts"
        data="{{companyPrompt}}"
        disabled="[[!_loadUserData]]"
      >
      </katapult-firebase-worker>
      <katapult-firebase-worker path="admin_accounts/[[user.uid]]" on-change="_systemAdminDataChanged" disabled="[[!_loadUserData]]">
      </katapult-firebase-worker>
      <paper-toast id="toast" duration="[[toastOptions.duration]]">
        <template is="dom-if" if="[[toastOptions.eula]]">
          <div>
            By continuing to use this software, you agree to the terms of the
            <a style="color:white;" target="_blank" href="/eula-page/">EULA</a>
          </div>
        </template>
        <template is="dom-if" if="[[toastOptions.close]]">
          <katapult-button icon="close" iconOnly noBorder textColor="white" on-click="closeToast"></katapult-button>
        </template>
      </paper-toast>
      <paper-dialog id="permissionDenied" no-cancel-on-outside-click no-cancel-on-esc-key>
        <div title amber>Permission Required</div>
        <div body>You do not have permission to view this page</div>
      </paper-dialog>
      <paper-dialog id="anotherSessionDialog" no-cancel-on-outside-click no-cancel-on-esc-key>
        <div title amber>Account In Use</div>
        <div body>
          Your account is being used on another device. You can either logout on this device or continue using this device and prompt the
          other device to logout.
        </div>
        <div buttons>
          <katapult-button on-click="_signOut">Logout</katapult-button>
          <katapult-button color="var(--paper-amber-500)" on-click="_claimSession">Use this Device</katapult-button>
        </div>
      </paper-dialog>
      <template is="dom-if" if="{{companyPrompt}}">
        <paper-dialog
          id="promptDialog"
          opened
          with-backdrop
          style="max-width: 400px; text-align: center"
          modal="{{companyPrompt.modal}}"
          on-iron-overlay-opened="patchOverlay"
        >
          <div title amber>{{companyPrompt.title}}</div>
          <div style="align-items: center; display: flex; flex-direction: column;">
            <div body>{{companyPrompt.body}}</div>
            <template is="dom-if" if="{{!companyPrompt.modal}}">
              <div buttons style="margin-top: 24px">
                <katapult-button color="var(--paper-amber-500)" dialog-dismiss>Okay</katapult-button>
              </div>
            </template>
          </div>
        </paper-dialog>
      </template>
      <katapult-status-notifier></katapult-status-notifier>
      <!-- Overlay -->
      <div id="overlay">
        <template is="dom-if" if="[[!showAuthContent]]">
          <div id="signInWrapper" class="flex">
            <template is="dom-if" if="[[!isMobile]]" restamp>
              <div id="backgroundImage" style$="background-image: url([[_backgroundUrl]])"></div>
            </template>
            <div id="signInContent" class="flex column">
              <div id="welcome">[[welcomeMessage]]</div>
              <div id="detailsFormContainer" class="flex">
                <div id="detailsContainer" class="flex column stretch">
                  <div id="titleContent">
                    <slot name="title-content"></slot>
                  </div>
                  <div id="descriptionContent">
                    <slot name="description-content"></slot>
                  </div>
                </div>
                <div id="formContainer" class="flex">
                  <template is="dom-if" if="[[loading]]">
                    <paper-spinner-lite id="loadingSpinner" active="[[loading]]"></paper-spinner-lite>
                  </template>
                  <template is="dom-if" if="[[!loading]]">
                    <div id="form">
                      <template is="dom-if" if="[[showDbSwitcher]]">
                        <div id="currentDb">
                          <div>[[currentDb]]</div>
                          <paper-menu-button id="dbSwitcherButton" no-overlap horizontal-align="right">
                            <katapult-button
                              icon="database"
                              iconOnly
                              noBorder
                              slot="dropdown-trigger"
                              on-click="focusDbSwitcherInput"
                              style="padding: 0;"
                            ></katapult-button>
                            <div slot="dropdown-content" id="dbSwitcherMenu">
                              <paper-input id="dbSwitcherInput" label="Switch Database" value="{{newDbUrl}}" no-label-float></paper-input>
                              <katapult-button icon="arrow_forward" iconOnly noBorder on-click="dbSwitch"></katapult-button>
                            </div>
                          </paper-menu-button>
                        </div>
                      </template>
                      <iron-a11y-keys keys="enter" on-keys-pressed="_focusNext"></iron-a11y-keys>
                      <iron-collapse id="signInForm" class="flex column stretch" opened="[[_activeForms.forbidden]]">
                        <p>
                          <slot name="forbidden-message">
                            You do not have permission to view this page.
                            <a class="highlighted-link" href="../[[config.firebaseData.homePage]]">Go back</a>?
                          </slot>
                        </p>
                      </iron-collapse>
                      <iron-collapse id="signInForm" class="flex column stretch" opened="[[_activeForms.signInForm]]">
                        <iron-collapse class="flex column stretch" opened="[[!_showMfaCode]]">
                          <!-- Alternate Sign-In Methods -->
                          <template
                            is="dom-if"
                            if="[[_calcShowAlternateSignInMethods(googleSignInEnabled, microsoftSignInEnabled, enterpriseSSOEnabled, enterpriseSSOSelected)]]"
                          >
                            <div id="ssoButtons">
                              <!-- Google Sign-In button -->
                              <template is="dom-if" if="[[googleSignInEnabled]]">
                                <div class="signInButton">
                                  <katapult-button
                                    on-click="_handleSsoButtonClicked"
                                    loading="[[_signingInGoogle]]"
                                    data-provider-id="google.com"
                                  >
                                    <img
                                      src="https://storage.googleapis.com/katapult-pro-shared-files/photos/g-logo.png"
                                      height="18"
                                      width="18"
                                      style="margin-left:4px;"
                                    />
                                    <span style="margin-left:18px;">Sign in with Google</span>
                                  </katapult-button>
                                  <template is="dom-if" if="[[googleSignInError]]">
                                    <div class="signInErrorMessage">[[googleSignInError]]</div>
                                  </template>
                                </div>
                              </template>
                              <!-- Microsoft Sign-In button -->
                              <template is="dom-if" if="[[microsoftSignInEnabled]]">
                                <div class="signInButton">
                                  <katapult-button
                                    on-click="_handleSsoButtonClicked"
                                    loading="[[_signingInMicrosoft]]"
                                    data-provider-id="microsoft.com"
                                  >
                                    <img
                                      src="https://storage.googleapis.com/katapult-pro-shared-files/photos/ms-symbollockup_mssymbol_19.svg"
                                      height="18"
                                      width="18"
                                      style="margin-left:4px;"
                                    />
                                    <span style="margin-left:18px;">Sign in with Microsoft</span>
                                  </katapult-button>
                                  <template is="dom-if" if="[[microsoftSignInError]]">
                                    <div class="signInErrorMessage">[[microsoftSignInError]]</div>
                                  </template>
                                </div>
                              </template>
                              <!-- Enterprise SSO Sign-In button -->
                              <template is="dom-if" if="[[enterpriseSSOEnabled]]">
                                <div class="signInButton">
                                  <katapult-button icon="shield" on-click="_enterpriseSSOSelected">
                                    <span style="margin-left:6px;">Sign in with Enterprise SSO</span>
                                  </katapult-button>
                                </div>
                              </template>
                            </div>
                            <template is="dom-if" if="[[showEmailSignIn]]">
                              <div style="margin-top:6px;display:flex;gap:15px;">
                                <hr class="ssoDivider" />
                                <span>or</span>
                                <hr class="ssoDivider" />
                              </div>
                            </template>
                          </template>
                          <!-- Email Sign-In interface -->
                          <template is="dom-if" if="[[_calcShowEmailSignInMethod(showEmailSignIn, enterpriseSSOSelected)]]">
                            <paper-input
                              id="emailInput"
                              autofocus$="[[!_focusPassword]]"
                              label="Email"
                              value="{{email}}"
                              error-message="[[_emailError]]"
                              invalid="[[_emailError]]"
                            ></paper-input>
                            <paper-input
                              id="passwordInput"
                              autofocus$="[[_focusPassword]]"
                              label="Password"
                              value="{{_password}}"
                              error-message="[[_passwordError]]"
                              invalid="[[_passwordError]]"
                              type="[[_calcPasswordInputType(_passwordVisible)]]"
                            >
                              <iron-a11y-keys keys="enter" on-keys-pressed="_signIn"></iron-a11y-keys>
                              <katapult-button
                                slot="suffix"
                                icon="[[_calcPasswordToggleIcon(_passwordVisible)]]"
                                iconOnly
                                noBorder
                                on-click="_togglePasswordVisible"
                                tabindex=""
                              ></katapult-button>
                            </paper-input>
                            <div flex>
                              <template is="dom-if" if="[[allowUnauthenticated]]">
                                <katapult-button color="var(--paper-grey-500)" on-click="_closeSignIn">Go Back</katapult-button>
                                <div style="min-width: 24px;"></div>
                              </template>
                              <katapult-button color="var(--secondary-color)" grow on-click="_signIn" loading="[[_signingIn]]"
                                >Sign In</katapult-button
                              >
                            </div>
                          </template>
                          <template is="dom-if" if="[[showEmailSignIn]]">
                            <span class="link" on-click="_showResetPasswordForm">[[resetPasswordText]]</span>
                          </template>
                          <template is="dom-if" if="[[!showEmailSignIn]]">
                            <span class="link" on-click="_revealSignIn">[[showEmailSignInMessage]]</span>
                          </template>
                          <!-- Enterprise SSO Sign-In interface -->
                          <template is="dom-if" if="[[enterpriseSSOSelected]]">
                            <paper-input
                              id="emailInput"
                              autofocus$="[[!_focusPassword]]"
                              label="Email"
                              value="{{email}}"
                              error-message="[[_emailError]]"
                              invalid="[[_emailError]]"
                            ></paper-input>
                            <katapult-button
                              color="var(--secondary-color)"
                              grow
                              on-click="_continueWithEnterpriseSSO"
                              loading="[[_signingIn]]"
                              >Continue with Enterprise SSO</katapult-button
                            >
                            <katapult-button style="margin-top:0;" grow on-click="_goBackToMainSignInForm">Go Back</katapult-button>
                          </template>
                        </iron-collapse>
                        <iron-collapse class="flex column stretch" opened="[[_showMfaCode]]">
                          <p>A 6-digit code has been sent to your email</p>
                          <p>Please enter it below:</p>
                          <paper-input
                            type="number"
                            label="6-digit code"
                            value="{{_mfaCode}}"
                            error-message="[[_mfaCodeError]]"
                            invalid="[[_passwordError]]"
                          >
                            <iron-a11y-keys keys="enter" on-keys-pressed="_checkMfaCode"></iron-a11y-keys>
                          </paper-input>
                          <katapult-button color="var(--secondary-color)" on-click="_checkMfaCode" loading="[[_checkingMfaCode]]"
                            >Sign In</katapult-button
                          >
                        </iron-collapse>
                        <iron-collapse class="flex column stretch" opened="[[!_showMfaCode]]">
                          <template is="dom-if" if="[[!noSignup]]">
                            <span class="link" on-click="_showSignUpForm">I do not have an account</span>
                          </template>
                          <span class="link" on-click="_openTermsOfService">Terms of Service and Privacy Policy</span>
                        </iron-collapse>
                      </iron-collapse>
                      <iron-collapse id="linkForm" class="flex column center" opened="[[_activeForms.linkForm]]">
                        <iron-collapse class="flex column" opened="[[!_linkError]]">
                          <p>[[_linkMessage]]</p>
                          <paper-spinner-lite active="[[_signingIn]]"></paper-spinner-lite>
                        </iron-collapse>
                        <iron-collapse class="flex column" opened="[[_linkError]]">
                          <p>[[_linkError]]</p>
                          <katapult-button color="var(--secondary-color)" on-click="_showSignInForm">Return to Sign In</katapult-button>
                        </iron-collapse>
                      </iron-collapse>
                      <iron-collapse id="changePasswordForm" class="flex column stretch" opened="[[_activeForms.changePasswordForm]]">
                        <iron-collapse class="flex column stretch" opened="[[passwordExpired]]">
                          <p>Your password has expired and must be changed</p>
                          <paper-input
                            label="Email"
                            value="{{email}}"
                            error-message="[[_emailError]]"
                            invalid="[[_emailError]]"
                          ></paper-input>
                          <paper-input
                            label="Password"
                            value="{{_password}}"
                            error-message="[[_passwordError]]"
                            invalid="[[_passwordError]]"
                            type="[[_calcPasswordInputType(_passwordVisible)]]"
                          >
                            <katapult-button
                              slot="suffix"
                              icon="[[_calcPasswordToggleIcon(_passwordVisible)]]"
                              iconOnly
                              noBorder
                              on-click="_togglePasswordVisible"
                              tabindex
                            ></katapult-button>
                          </paper-input>
                        </iron-collapse>
                        <paper-input
                          label="New Password"
                          value="{{_newPassword}}"
                          error-message="[[_newPasswordError]]"
                          invalid="[[_newPasswordError]]"
                          type="[[_calcPasswordInputType(_passwordVisible)]]"
                          on-keyup="_checkPasswordStrength"
                        >
                          <iron-a11y-keys keys="enter" on-keys-pressed="_signIn"></iron-a11y-keys>
                          <katapult-button
                            slot="suffix"
                            icon="[[_calcPasswordToggleIcon(_passwordVisible)]]"
                            iconOnly
                            noBorder
                            on-click="_togglePasswordVisible"
                            tabindex
                          ></katapult-button>
                        </paper-input>
                        <div>
                          <paper-progress
                            class="password-meter"
                            value$="[[passwordStrength]]"
                            min="-0.2"
                            max="4"
                            step="0.2"
                          ></paper-progress>
                        </div>
                        <paper-input
                          label="Confirm New Password"
                          value="{{_confirmNewPassword}}"
                          error-message="[[_confirmNewPasswordError]]"
                          invalid="[[_confirmNewPasswordError]]"
                          type="[[_calcPasswordInputType(_passwordVisible)]]"
                        >
                          <iron-a11y-keys keys="enter" on-keys-pressed="_changePassword"></iron-a11y-keys>
                          <katapult-button
                            slot="suffix"
                            icon="[[_calcPasswordToggleIcon(_passwordVisible)]]"
                            iconOnly
                            noBorder
                            on-click="_togglePasswordVisible"
                            tabindex
                          ></katapult-button>
                        </paper-input>
                        <template is="dom-if" if="[[!_showResendResetLink]]">
                          <paper-checkbox style="margin-top:16px; text-align:center;" checked="{{eulaAgreed}}"
                            >I agree to the terms of the
                            <a style="color:var(--secondary-color);" target="_blank" href="/eula-page/">EULA</a></paper-checkbox
                          >
                          <katapult-button color="var(--secondary-color)" on-click="_changePassword" loading="[[_changingPassword]]"
                            >Change Password</katapult-button
                          >
                        </template>
                        <template is="dom-if" if="[[_showResendResetLink]]">
                          <katapult-button on-click="_sendPasswordResetEmail" loading="[[_sendingResetPasswordEmail]]"
                            >Resend Link</katapult-button
                          >
                        </template>
                        <template
                          is="dom-if"
                          if="[[_calcShowAlternateSignInMethods(googleSignInEnabled, microsoftSignInEnabled, enterpriseSSOEnabled, enterpriseSSOSelected)]]"
                        >
                          <div style="margin-top:6px;margin-bottom:16px;display:flex;gap:15px;">
                            <hr class="ssoDivider" />
                            <span>or</span>
                            <hr class="ssoDivider" />
                          </div>
                          <div id="ssoButtons">
                            <!-- Google Sign-In button -->
                            <template is="dom-if" if="[[googleSignInEnabled]]">
                              <div class="signInButton">
                                <katapult-button
                                  on-click="_handleSsoButtonClicked"
                                  loading="[[_signingInGoogle]]"
                                  data-provider-id="google.com"
                                >
                                  <img
                                    src="https://storage.googleapis.com/katapult-pro-shared-files/photos/g-logo.png"
                                    height="18"
                                    width="18"
                                    style="margin-left:4px;"
                                  />
                                  <span style="margin-left:18px;">Sign in with Google</span>
                                </katapult-button>
                                <template is="dom-if" if="[[googleSignInError]]">
                                  <div class="signInErrorMessage">[[googleSignInError]]</div>
                                </template>
                              </div>
                            </template>
                            <!-- Microsoft Sign-In button -->
                            <template is="dom-if" if="[[microsoftSignInEnabled]]">
                              <div class="signInButton">
                                <katapult-button
                                  on-click="_handleSsoButtonClicked"
                                  loading="[[_signingInMicrosoft]]"
                                  data-provider-id="microsoft.com"
                                >
                                  <img
                                    src="https://storage.googleapis.com/katapult-pro-shared-files/photos/ms-symbollockup_mssymbol_19.svg"
                                    height="18"
                                    width="18"
                                    style="margin-left:4px;"
                                  />
                                  <span style="margin-left:18px;">Sign in with Microsoft</span>
                                </katapult-button>
                                <template is="dom-if" if="[[microsoftSignInError]]">
                                  <div class="signInErrorMessage">[[microsoftSignInError]]</div>
                                </template>
                              </div>
                            </template>
                            <!-- Enterprise SSO Sign-In button -->
                            <template is="dom-if" if="[[enterpriseSSOEnabled]]">
                              <div class="signInButton">
                                <katapult-button icon="shield" on-click="_enterpriseSSOSelected">
                                  <span style="margin-left:6px;">Sign in with Enterprise SSO</span>
                                </katapult-button>
                              </div>
                            </template>
                          </div>
                        </template>
                      </iron-collapse>
                      <iron-collapse id="resetPasswordForm" class="flex column stretch" opened="[[_activeForms.resetPasswordForm]]">
                        <p>A password reset link will be sent to the following email address:</p>
                        <paper-input
                          id="emailInput"
                          label="Email"
                          value="{{email}}"
                          error-message="[[_emailError]]"
                          invalid="[[_emailError]]"
                        >
                          <iron-a11y-keys keys="enter" on-keys-pressed="_sendPasswordResetEmail"></iron-a11y-keys>
                        </paper-input>
                        <div class="flex even noShrink" buttons>
                          <katapult-button on-click="_showSignInForm">Cancel</katapult-button>
                          <katapult-button
                            color="var(--secondary-color)"
                            on-click="_sendPasswordResetEmail"
                            loading="[[_sendingResetPasswordEmail]]"
                            >Reset Password</katapult-button
                          >
                        </div>
                      </iron-collapse>
                      <iron-collapse id="signUpForm" class="flex column stretch" opened="[[_activeForms.signUpForm]]">
                        <div><slot name="sign-up-form"></slot></div>
                        <span class="link" on-click="_showSignInForm">I already have an account</span>
                      </iron-collapse>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template is="dom-if" if="[[showAuthContent]]">
          <template is="dom-if" if="[[showMyAccount]]">
            <katapult-button
              style="position: fixed; top: 12px; right: 16px;"
              icon="clear"
              iconOnly
              noBorder
              on-click="closeMyAccount"
            ></katapult-button>
            <katapult-account-settings
              password="{{_password}}"
              new-password="{{_newPassword}}"
              confirm-new-password="{{_confirmNewPassword}}"
              changing-password="{{_changingPassword}}"
              password-error="{{_passwordError}}"
              new-password-error="{{_newPasswordError}}"
              confirm-new-password-error="{{_confirmNewPasswordError}}"
              password-strength="[[passwordStrength]]"
              root-company="[[rootCompany]]"
              uid="[[user.uid]]"
              user-record="[[userRecord]]"
            >
              <slot name="preferences-content" slot="account-options">
                <div>No account options available for this Katapult Pro page.</div>
              </slot>
            </katapult-account-settings>
            <!--<div id="myAccountWrapper" class="flex">-->
            <!--  <div id="myAccountContent">-->
            <!--    <div>-->
            <!--      <iron-a11y-keys keys="enter" on-keys-pressed="_focusNext"></iron-a11y-keys>-->
            <!--      <span class="title">Change Password</span>-->
            <!--      <paper-input value="{{_password}}" label="Current Password" type="password" error-message="[[_passwordError]]" invalid="[[_passwordError]]"></paper-input>-->
            <!--      <paper-input value="{{_newPassword}}" label="New Password" type="password" error-message="[[_newPasswordError]]" invalid="[[_newPasswordError]]"></paper-input>-->
            <!--      <paper-input value="{{_confirmNewPassword}}" label="Confirm New Password" error-message="[[_confirmNewPasswordError]]" invalid="[[_confirmNewPasswordError]]" type="password">-->
            <!--        <iron-a11y-keys keys="enter" on-keys-pressed="_changePassword"></iron-a11y-keys>-->
            <!--      </paper-input>-->
            <!--      <div class="flex spaceBetween">-->
            <!--        <katapult-button color="var(--secondary-color)" on-click="_changePassword" loading="[[_changingPassword]]">Change Password</katapult-button>-->
            <!--        <iron-collapse class="message success" opened="[[_passwordChanged]]" horizontal>Password Changed<iron-icon icon="check-circle"></iron-icon></iron-collapse>-->
            <!--      </div>-->
            <!--    </div>-->
            <!-- <div>-->
          </template>
        </template>
      </div>
      <template is="dom-if" if="[[showAuthContent]]" restamp="">
        <slot name="auth-content"></slot>
      </template>
    `;
  }

  static get is() {
    return 'katapult-auth';
  }
  static get properties() {
    return {
      // Public Properties.
      activeForm: {
        type: String,
        value: 'signInForm',
        notify: true,
        observer: '_activeFormChanged'
      },
      admin: {
        type: Boolean,
        value: null,
        notify: true,
        readOnly: true
      },
      allowUnauthenticated: {
        type: Boolean,
        value: false
      },
      blockAuthContent: {
        type: Boolean,
        value: false
      },
      canRead: {
        type: Boolean,
        value: false,
        notify: true,
        readOnly: true
      },
      company: {
        type: String,
        value: '',
        notify: true,
        readOnly: true
      },
      canWrite: {
        type: Boolean,
        value: false,
        notify: true,
        readOnly: true
      },
      currentDb: {
        type: String,
        value: function () {
          const database = window.config.appName;
          if (database == 'katapult-production') {
            return 'katapult-pro';
          } else if (database == 'ppl-training-planner') {
            return 'utilis-maps';
          } else if (database == 'fir-pole-applications') {
            return 'demo';
          } else if (database) {
            return database;
          } else {
            return 'katapult-pro';
          }
        },
        notify: true
      },
      email: {
        type: String,
        value: '',
        notify: true
      },
      enabledFeatures: {
        type: Object,
        value: () => ({})
      },
      showEmailSignIn: {
        type: Boolean,
        value: true
      },
      googleSignInEnabled: {
        type: Boolean,
        value: false
      },
      googleSignInError: {
        type: String,
        value: null
      },
      microsoftSignInEnabled: {
        type: Boolean,
        value: false
      },
      microsoftSignInError: {
        type: String,
        value: null
      },
      enterpriseSSOEnabled: {
        type: Boolean,
        value: false
      },
      enterpriseSSOSelected: {
        type: Boolean,
        value: false
      },
      hasSession: {
        type: Boolean,
        value: false,
        notify: true
      },
      _focusPassword: {
        type: Boolean,
        value: false
      },
      jobToken: {
        type: String,
        value: null,
        notify: true
      },
      loading: {
        type: Boolean,
        value: true
      },
      newVersionAvailable: {
        type: Boolean,
        value: false,
        notify: true
      },
      noSignup: {
        type: Boolean,
        value: false
      },
      page: {
        type: String,
        value: null
      },
      pendingPermission: {
        type: Boolean,
        value: false,
        notify: true,
        readOnly: true
      },
      publicFeatureFlagsAreLoaded: {
        type: Boolean,
        value: false
      },
      readOnly: {
        type: Boolean,
        value: false,
        notify: true,
        readOnly: true
      },
      resetPasswordText: {
        type: String,
        value: 'I forgot my password'
      },
      rootCompany: {
        type: String,
        notify: true,
        readOnly: true
      },
      showAuthContent: {
        type: Boolean,
        value: false,
        notify: true,
        reflectToAttribute: true
      },
      showEmailSignInMessage: {
        type: String,
        computed: 'getShowEmailSignInMessage()'
      },
      showDbSwitcher: {
        type: Boolean,
        value: false,
        computed: 'dbShowSwitcher(_activeForms.signInForm, _showMfaCode, email)'
      },
      showMyAccount: {
        type: Boolean,
        value: false,
        reflectToAttribute: true
      },
      showSignIn: {
        type: Boolean,
        value: false
      },
      signedIn: {
        type: Boolean,
        value: false,
        notify: true,
        observer: '_signedInChanged',
        reflectToAttribute: true
      },
      // If signupLink exists, will redirect to link for signups
      signupLink: {
        type: String,
        value: null
      },
      statusKnown: {
        type: Boolean,
        value: false,
        notify: true,
        reflectToAttribute: true,
        observer: '_statusKnownChanged'
      },
      systemAdmin: {
        type: Boolean,
        value: null,
        notify: true,
        readOnly: true
      },
      title: {
        type: String,
        observer: 'titleChanged'
      },
      user: {
        type: Object,
        notify: true,
        observer: '_userChanged'
      },
      userGroup: {
        type: String,
        notify: true,
        readOnly: true,
        observer: '_userGroupChanged'
      },
      userRecord: {
        type: Object,
        notify: true,
        observer: '_userRecordChanged'
      },
      welcomeMessage: {
        type: String,
        value: 'Welcome To'
      },
      // Private Properties.
      _activeForms: {
        type: Object,
        value: () => ({})
      },
      _backgroundUrl: {
        type: String,
        computed: '_getBackgroundUrl(config.firebaseData.login_backgrounds)'
      },
      _hashParameters: {
        type: Object
      },
      _loadUserData: {
        type: Boolean,
        value: false
      },
      _passwordVisible: {
        type: Boolean,
        value: false
      },
      _showMfaCode: {
        type: Boolean,
        value: false
      },
      _userPermissions: {
        type: Object,
        observer: '_userPermissionsChanged'
      },
      _queryParameters: {
        type: Object
      },
      katapultSysAdmin: {
        type: Boolean,
        value: false,
        notify: true
      }
    };
  }
  constructor() {
    super();
    this.isMobile = self.isMobile;
    this.isChromeExtension = self.chrome?.runtime?.id != null;
    this.newDbUrl = '';

    this.focusDbSwitcherInput = () => setTimeout(() => this.shadowRoot?.querySelector('#dbSwitcherInput')?.focus(), 100);
  }
  connectedCallback() {
    super.connectedCallback();

    // Setup listener to detect when public flags are loaded
    this.publicFlagsLoadedListener = addPublicFlagsLoadedListener((loaded) => {
      this.publicFeatureFlagsAreLoaded = loaded;
    });
    // Check if alternate sign in methods are enabled
    this.hideEmailSignInFlagListener = addFlagListener('hide_email_sign_in', (enabled) => {
      // email sign in is enabled by default, this flag is only to turn it off
      this.showEmailSignIn = !enabled;
    });
    this.googleSignInFlagListener = addFlagListener('google_sign_in', (enabled) => {
      this.googleSignInEnabled = enabled;
    });
    this.microsoftSignInFlagListener = addFlagListener('microsoft_sign_in', (enabled) => {
      this.microsoftSignInEnabled = enabled;
    });
    this.enterpriseSSOFlagListener = addFlagListener('enterprise_sso', (enabled) => {
      this.enterpriseSSOEnabled = enabled;
    });
  }
  disconnectedCallback() {
    super.disconnectedCallback();

    removePublicFlagsLoadedListener(this.publicFlagsLoadedListener);
    removeFlagListener('hide_email_sign_in', this.hideEmailSignInFlagListener);
    removeFlagListener('google_sign_in', this.googleSignInFlagListener);
    removeFlagListener('microsoft_sign_in', this.microsoftSignInFlagListener);
    removeFlagListener('enterprise_sso', this.enterpriseSSOFlagListener);
  }
  static get observers() {
    return [
      '_forceLogouts(user.email)',
      '_urlMfa(_queryParameters.u, _queryParameters.t)',
      '_urlReset(_queryParameters.u, _queryParameters.t)',
      '_authWithJobToken(_hashParameters.job_id, _hashParameters.job_token, _queryParameters.job_token)',
      '_checkSession(isMobile, deviceId, userRecord.session_info.mobile_device_id, signedIn)',
      '_checkVersion(config.buildNumber, buildNumbers.*)',
      '_checkPermission(userRecord.workflows.*, blockAuthContent)',
      '_checkForceLogout(rootCompany, user.uid, userRecord.force_logout, userRecord.force_logout_on_refresh, userRecordLoading)',
      '_calcShowAuthContent(statusKnown, signedIn, userRecord, permissionToViewThisPage, anonymousAuthToken, allowUnauthenticated, showSignIn)',
      '_calcLoading(statusKnown, signedIn, userRecord, publicFeatureFlagsAreLoaded)',
      '_updateSentryUserRecord(user.email, user.uid, userGroup)',
      '_updateFeatureFlagsContext(user.uid, userGroup)',
      'setLastActive(user.uid, rootCompany)'
    ];
  }
  ready() {
    super.ready();

    if (!window.katapultAuth) {
      window.katapultAuth = this;
      window.dispatchEvent(new CustomEvent('katapult-auth-initialized'));
    } else console.warn('Multiple Katapult Auth Elements');

    this.appConfiguration = new AppConfiguration(this);

    FirebaseWorker.onChange('deviceId', (deviceId) => {
      this.deviceId = deviceId;
    });

    // Get query parameters.
    let temp = {};
    window.location.search
      .slice(1)
      .split('&')
      .forEach((x) => {
        let parts = x.split('=');
        if (parts[0] == 'e') {
          this.email = parts[1];
          this._focusPassword = true;
        } else {
          temp[parts[0]] = parts[1];
        }
      });
    this._queryParameters = temp;

    // Get has parameters.
    try {
      temp = decodeURI(window.location.hash.substring(1));
      if (temp.length > 0) temp = JSON.parse(temp);
    } catch (e) {
      temp = {};
      // Split the hash up by /
      let keys = window.location.hash.substring(1).split('/');
      // The job id should be the first item in the hash list
      if (keys[0].search(/^[\w\-]{20}$/) == 0 || keys[0] == 'covid-19') temp.job_id = keys[0];
      // The auth token should be the third item in the hash list
      if (keys[3]) {
        temp.job_token = keys[3];
      }
    }
    this._hashParameters = temp;

    // Check if there is a redirect result
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {})
      .catch((error) => {
        console.log(`error`, error);
        if (error.message?.includes('google.com')) {
          if (error.message?.includes('auth/invalid-auth')) {
            this.googleSignInError = 'Google sign-in is not enabled for this email';
          }
        } else if (error.message?.includes('microsoft.com')) {
          if (error.message?.includes('auth/invalid-auth')) {
            this.microsoftSignInError = 'Microsoft sign-in is not enabled for this email';
          }
        }
        throw new Error(`Error signing in: ${error.message}`);
      });
  }
  _revealSignIn() {
    this.showEmailSignIn = true;
  }
  getShowEmailSignInMessage() {
    // determine the message to show to reveal email sign in (if it's hidden)
    switch (config.appName) {
      case 'davey-katapult-pro':
        return 'Not a Davey employee?';
      default:
        return 'Sign in with email and password';
    }
  }
  equal(a, b) {
    return a == b;
  }
  async _authWithJobToken() {
    let jobId = this.get('_hashParameters.job_id');
    let jobToken = this.get('_hashParameters.job_token') || this.get('_queryParameters.job_token');
    this.jobToken = jobToken;
    if (jobId && jobToken) {
      // Attempt to get the job name to see if the user already has access to the job.
      // If they do, then don't do anything with the token so we don't cause authentication issues
      let jobData = undefined;
      try {
        jobData = await FirebaseWorker.ref(`photoheight/jobs/${jobId}/metadata/note`)
          .once('value')
          .then((s) => s.val());
      } catch (err) {}
      // Only use the token if no job name was found
      if (jobData === undefined) {
        // Set mfa form.
        this.activeForm = 'linkForm';
        // Clear mfa error.
        this._linkError = '';
        // Set mfa message.
        this._linkMessage = 'Authenticating...';
        // Set signing in flag.
        this._signingIn = true;
        firebase
          .functions()
          .httpsCallable('generateAuthTokenFromJobToken')({ job_id: jobId, job_token: jobToken })
          .then((res) => {
            // Check that the data sent back is all valid
            if (Path.get(res, 'data.job_id') && Path.get(res, 'data.auth_token') && Path.get(res, 'data.job_id') === jobId) {
              this.authenticateAnonymousSession(res.data.auth_token, res.data.job_id);
            }
          })
          .catch((err) => {
            console.log('Error', err);
            this._linkMessage = 'Error Authenticating.';
            this._signingIn = false;
            this.toast('Error: ' + err);
          });
      }
    }
  }
  authenticateAnonymousSession(token, jobId) {
    // Since we are loading the job via a temporary auth token, then
    // set the auth persistence so the auth is only valid for the tab or window
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.NONE)
      .then(() => {
        // Save the auth token to be used for later authenticating web workers
        this.anonymousAuthToken = token;
        this._linkMessage = 'Successfully Authenticated';
        setTimeout(() => {
          this.$.auth.signInWithCustomToken(token).then(() => {
            this._setCompany('shareableLink');
            this._setUserGroup('_custom_auth');
            this._signingIn = false;
            this._onSignedIn();
            this.toast('', { close: true, duration: 0, eula: true });
            setTimeout(() => {
              if (this.activeForm != 'forbidden') this._showSignInForm();
            }, 400);
          });
        }, 1000);
      });
  }
  toast(text, options = {}) {
    options.duration ??= 3000;
    this.toastOptions = options;
    this.$.toast.show(text);
  }
  async _checkForceLogout(rootCompany, uid, forceLogout, forceLogoutOnRefresh, userRecordLoading) {
    if (rootCompany && uid && !userRecordLoading) {
      if (forceLogout || forceLogoutOnRefresh) {
        let claims = await this.user.getIdTokenResult().then((result) => result.claims);
        let authTime = claims.auth_time * 1000;
        if (
          (forceLogout && authTime < forceLogout) ||
          (forceLogoutOnRefresh && authTime < forceLogoutOnRefresh && !this.checkedForceLogout)
        ) {
          this._signOut();
        }
      }
      this.checkedForceLogout = true;
    }
  }
  async _checkPasswordStrength(e) {
    const userInput = e.currentTarget.value;
    await import('zxcvbn-typescript/dist/zxcvbn.js'); // Source of window.zxcvbn
    const result = checkPassword(userInput, self.config?.firebaseData?.password, window.zxcvbn?.zxcvbn);
    this.passwordStrength = result.score;
  }
  _checkPermission() {
    // TODO (07-14-2023): These checks could use the blockAuthContent system.
    const notMobileAssessmentUser = this.get('userRecord.workflows.mobile_assessment') != true;
    const isMobileAssessmentAllowedPage = ['/mobile/', '/upload/'].some((x) => location.href.includes(x));

    this.permissionToViewThisPage = (notMobileAssessmentUser || isMobileAssessmentAllowedPage) && !this.blockAuthContent;
  }
  async _checkSession() {
    let hasSession = false;
    if (self.isMobile) {
      if (this.signedIn && this.deviceId && this.userRecord) {
        // Automatically claim session if no device_id is set.
        let lastUpdated = new Date(this.get('userRecord.session_info.mobile_device_id_last_updated'));
        let today = new Date();
        // Allow session to be claimed if there is no device_id or it was last updated on a day other than today.
        if (
          this.get('userRecord.session_info.mobile_device_id') == null ||
          (lastUpdated != 'Invalid Date' &&
            (lastUpdated.getFullYear() != today.getFullYear() ||
              lastUpdated.getMonth() != today.getMonth() ||
              lastUpdated.getDate() != today.getDate()))
        ) {
          await this._claimSession();
          hasSession = true;
        } else if (this.get('userRecord.session_info.mobile_device_id') == this.deviceId) {
          hasSession = true;
        }
      }
    }
    this.hasSession = hasSession;
  }
  setLastActive() {
    if (this.user && this.user.uid && this.rootCompany)
      FirebaseWorker.ref(`users/${this.rootCompany}/${this.user.uid}/last_active`).set(firebase.database.ServerValue.TIMESTAMP);
  }
  // TODO (02-21-2023): Made this a no-op for now. This will change when we redo the version dialog.
  _checkVersion() {
    let newVersionAvailable = false;
    // if (!['prev', 'next'].some(x => window.location.href.includes(x))) {
    //   let currentBuild = parseInt(this.get('config.buildNumber'));
    //   if (!isNaN(currentBuild)) {
    //     let minimumRequired = parseInt(this.get('buildNumbers.minimum_required'));
    //     let minimumRequiredForThisPage = parseInt(this.get(`buildNumbers.${this.page}`));
    //     // If we are less than minimum required for this page or the minimum required for the platform.
    //     if (!isNaN(minimumRequiredForThisPage) && currentBuild < minimumRequiredForThisPage || !isNaN(minimumRequired) && currentBuild < minimumRequired) {
    //       newVersionAvailable = true;
    //     }
    //   }
    // }
    this.newVersionAvailable = newVersionAvailable;
  }
  async _claimSession() {
    if (this.rootCompany && this.user && this.user.uid && this.deviceId)
      await FirebaseWorker.ref(`users/${this.rootCompany}/${this.user.uid}/session_info`).update({
        mobile_device_id: this.deviceId,
        mobile_device_id_last_updated: Date.now()
      });
  }
  closeMyAccount() {
    this.showMyAccount = false;
    // Clear all inputs.
    this._password = this._newPassword = this._confirmNewPassword = this._newEmailPassword = this._newEmail = this._confirmNewEmail = '';
    // Clear flags.
    this._emailChanged = this._passwordChanged = false;
  }
  closeToast() {
    this.$.toast.close();
  }
  dbShowSwitcher() {
    return (
      (self.originIsDev || document.location.origin.includes('beta.katapultpro.com')) && this._activeForms.signInForm && !this._showMfaCode
    );
  }
  dbSwitch() {
    // De-alias database name.
    const databaseAliases = Object.fromEntries(
      Object.entries(this.config.firebaseConfigs).flatMap(([key, { aliases }]) => aliases.map((x) => [x, key]))
    );

    // Get database name using the alias list above, or the auth domain in the config.
    const databaseName =
      databaseAliases[this.newDbUrl] ??
      Object.values(databaseAliases).find((x) => x == this.newDbUrl) ??
      Object.values(this.config.firebaseConfigs).find((x) => {
        try {
          return (
            new URL(this.newDbUrl.startsWith('https://') ? this.newDbUrl : 'https://' + this.newDbUrl).hostname ==
            new URL('https://' + x.authDomain).hostname
          );
        } catch (e) {
          // Catch URl errors
          console.warn(e);
          return false;
        }
      })?.projectId;

    // Warn if we failed to get a valid name.
    if (!databaseName) {
      /** @type {import('@polymer/paper-input/paper-input.js').PaperInputElement|null} */
      const switcher = this.shadowRoot?.querySelector('#dbSwitcherInput') || null;
      if (!!switcher) switcher.invalid = true;
      return;
    }

    AppName.set(databaseName);
    window.location.reload();
  }
  getUrl() {
    return (
      location.origin +
      '/' +
      location.pathname
        .split('/')
        .filter((x) => x)
        .slice(0, -1)
        .join('/')
    );
  }
  openMyAccount() {
    this.showMyAccount = true;
  }
  titleChanged(title) {
    document.title = title;
  }
  _activeFormChanged(activeForm) {
    // Update activeForms.
    for (let key in this._activeForms) if (key != activeForm) this.set(`_activeForms.${key}`, false);
    this.set(`_activeForms.${activeForm}`, true);

    if (activeForm != 'signInForm' && activeForm != 'resetPasswordForm' && activeForm != 'changePasswordForm')
      this._password = this._passwordError = '';
    if (activeForm != 'signInForm' && activeForm != 'resetPasswordForm') this._emailError = '';
    if (activeForm != 'changePasswordForm') this._showResendResetLink = false;
  }
  _calcLoading() {
    this.loading = !this.statusKnown || (this.signedIn && this.userRecord == null) || !this.publicFeatureFlagsAreLoaded;
  }
  _calcShowEmailSignInMethod(emailEnabled, enterpriseSSOSelected) {
    return emailEnabled && !enterpriseSSOSelected;
  }
  _calcPasswordInputType(_passwordVisible) {
    return _passwordVisible ? 'text' : 'password';
  }
  _calcPasswordToggleIcon(_passwordVisible) {
    return _passwordVisible ? 'visibility_off' : 'visibility';
  }
  _calcShowAuthContent() {
    const hasUserRecord = this.userRecord != null;
    const loginIsValid = this.statusKnown && this.signedIn && (hasUserRecord || this.anonymousAuthToken);

    this.showAuthContent = !this.showSignIn && ((loginIsValid && this.permissionToViewThisPage) || this.allowUnauthenticated);

    if (this.signedIn && hasUserRecord && !this.permissionToViewThisPage) this.activeForm = 'forbidden';
  }
  async _changePassword(skipEulaCheck) {
    // Clear warnings.
    this._passwordError = this._newPasswordError = this._confirmNewPasswordError = this._emailError = '';
    // Clear success message.
    this._passwordChanged = false;

    // Make sure passwords are valid.
    if (!this._newPassword) this._newPasswordError = 'New password required';
    else {
      // Verify that the password meets server and system requirements.
      await import('zxcvbn-typescript/dist/zxcvbn.js'); // Source of window.zxcvbn
      const result = checkPassword(this._newPassword, self.config?.firebaseData?.password, window.zxcvbn?.zxcvbn);
      if (result.errorMessage) this._newPasswordError = result.errorMessage;
    }

    // Check for other input errors.
    if (!this._confirmNewPassword) this._confirmNewPasswordError = 'Confirm new password required';
    else if (this._confirmNewPassword != this._newPassword) this._confirmNewPasswordError = 'Does not match new password';
    else if (!this.eulaAgreed && skipEulaCheck !== true) this._confirmNewPasswordError = 'Please accept the EULA';

    // Create change password request object.
    let changePasswordRequest = null;
    // Reset specific steps.
    if (decodeURIComponent(this.get('_queryParameters.f')) == 'reset') {
      let uid = decodeURIComponent(this.get('_queryParameters.u'));
      let token = decodeURIComponent(this.get('_queryParameters.t'));
      let signUpSessionId = decodeURIComponent(this.get('_queryParameters.s'));
      // Make sure that everything required for changing password with reset token is valid.
      if (!this._newPasswordError && !this._confirmNewPasswordError && uid && token) {
        changePasswordRequest = { uid, token, newPassword: this._newPassword };
        if (signUpSessionId) changePasswordRequest.signUpSessionId = signUpSessionId;
      }
    }
    // General change specific steps.
    else {
      // Validate password.
      if (!this._password) this._passwordError = 'Current password required';
      let email = null;
      // Try to get email from input.
      if (this.email) {
        // Sanitize email.
        this.email = this._sanitizeEmail(this.email);
        // Validate email.
        this._emailError = this._validateEmail(this.email);
        email = this.email;
      }
      // Try to get email from user.
      else {
        let user = firebase.auth().currentUser;
        if (user) email = user.email;
      }
      // Make sure that everything required for changing password with credentials is valid.
      if (
        email &&
        (!this.email || !this._emailError) &&
        !this._passwordError &&
        !this._newPasswordError &&
        !this._confirmNewPasswordError
      ) {
        changePasswordRequest = { email, password: this._password, newPassword: this._newPassword };
      }
    }

    if (changePasswordRequest) {
      // Change password.
      this._changingPassword = true;
      await firebase
        .functions()
        .httpsCallable('changePassword')(changePasswordRequest)
        .then((res) => {
          // Set password changed flag.
          this._passwordChanged = true;
          // Save email and password.
          this.email = res.data.email;
          this._password = this._newPassword;
          // Clear inputs.
          this._newPassword = this._confirmNewPassword = '';
          // Attempt to sign in if signed out and just changed password with email.
          if (!this.signedIn) {
            if (this._token) {
              this.$.auth.signInWithCustomToken(this._token).then(() => {
                this.email = this._password = '';
                this._onSignedIn();
              });
            } else {
              this._showSignInForm();
              this._signIn();
            }
          }
        })
        .catch((err) => {
          if (err.message == 'changePassword/wrong-password') this._passwordError = 'Wrong Password';
          else if (err.message == 'changePassword/same-password') this._newPasswordError = 'You cannot use your current password';
          else if (err.message == 'changePassword/recently-used') this._newPasswordError = 'You have used this password before';
          else if (err.message == 'changePassword/invalid-password') this._newPasswordError = 'Password does not meet system requirements';
          else if (err.message == 'changePassword/expired-token') {
            this._newPasswordError = 'This link has expired. Click the button below to generate a new link.';
            this._showResendResetLink = true;
          } else if (err.message == 'changePassword/invalid-token') {
            this._newPasswordError = 'This link is invalid. Click the button below to generate a new link.';
            this._showResendResetLink = true;
          } else if (err.message == 'changePassword/failed-to-sync') {
            this._newPasswordError = 'Failed to update password.  Please contact customer support';
          } else if (err.message == 'INTERNAL')
            this._newPasswordError = 'An unexpected error has occurred. Please contact Katapult Pro Support';
          else this._passwordError = err.message;
          console.warn('Password Change Error', err);
        })
        .finally(() => {
          this._changingPassword = false;
        });
    }
  }
  async requestUpdatedClaims(requestedUserGroup) {
    let idToken = await firebase.auth().currentUser.getIdToken();
    await firebase.functions().httpsCallable('auth_v2')({ uid: firebase.auth().currentUser.uid, idToken, requestedUserGroup });
  }
  _checkMfaCode() {
    this._mfaCodeError = '';
    if (this._mfaCodeUid) {
      let parsedCode = parseInt(this._mfaCode).toString();
      if (!isNaN(parsedCode) && parsedCode.length === 6) {
        this._checkingMfaCode = true;
        firebase
          .functions()
          .httpsCallable('auth_v2')({ uid: this._mfaCodeUid, token: parsedCode })
          .then((res) => {
            if (res.data.token) {
              this._linkMessage = 'Successfully Authenticated';
              setTimeout(() => {
                this.passwordExpired = res.data.passwordExpired;
                if (this.passwordExpired) {
                  this._token = res.data.token;
                  setTimeout(() => {
                    this._checkingMfaCode = false;
                    this._showChangePasswordForm();
                  }, 1000);
                } else {
                  this.$.auth.signInWithCustomToken(res.data.token).then(() => {
                    this._checkingMfaCode = false;
                    this.email = this._password = this._mfaCode = '';
                    this._mfaCodeUid = null;
                    this._onSignedIn();
                    setTimeout(() => {
                      if (this.activeForm != 'forbidden') this._showSignInForm();
                    }, 400);
                  });
                }
              }, 1000);
            }
          })
          .catch((err) => {
            if (err.message == 'auth/katapult-inactive-user') {
              this._mfaCodeError = 'Your account is not active. Please contact Katapult for assistance.';
            } else if (err.message == 'auth/mfa-token-expired') {
              this._mfaCodeError = 'This code has expired';
            } else if (err.message == 'auth/invalid-token') {
              this._mfaCodeError = 'This code is invalid';
            } else if (err.message == 'auth/invalid-auth') {
              this._mfaCodeError = `Invalid authentication.  Please refresh the page and try again.`;
            } else this._mfaCodeError = 'An unexpected error has occurred. Please contact Katapult Pro Support';
            this._checkingMfaCode = false;
          });
      } else {
        this._mfaCodeError = 'Please enter a valid 6-digit code';
      }
    } else {
      this._mfaCodeError = 'An unexpected error has occurred. Please contact Katapult Pro Support';
    }
  }
  _closeSignIn() {
    this.showSignIn = false;
  }
  _companyAdminDataChanged(e) {
    this._setAdmin(e.detail != null ? true : false);
  }
  _focusNext(e) {
    if (this.shadowRoot.querySelector('#dbSwitcherButton') != null && this.shadowRoot.querySelector('#dbSwitcherButton').opened) {
      this.dbSwitch();
    } else {
      // Get parent element.
      let parent = e.target.parentElement;
      // Input selector.
      let selector = 'paper-input, drop-down, paper-textarea, input-element';
      // Get form.
      let form = this.$[this.activeForm];
      // Get all inputs in parent.  Also find slots.
      let inputs = [].slice.call(parent.querySelectorAll(selector + ', slot'));
      // Loop through the inputs and find any slots.
      inputs.forEach((input, i) => {
        if (input.localName == 'slot') {
          // Add any inputs found on them to the input list.
          let slotInputs = [];
          input.assignedNodes().forEach((node) => {
            slotInputs = slotInputs.concat([].slice.call((node.shadowRoot || node).querySelectorAll(selector)));
          });
          // Insert slot inputs in place of the slot in the inputs list.
          inputs = inputs.slice(0, i).concat(slotInputs, inputs.slice(i + 1));
        }
      });
      let focusedElem, focusedIndex;
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].focused) {
          focusedElem = inputs[i];
          focusedIndex = i;
          break;
        }
      }
      if (focusedElem) {
        let nextIndex = focusedIndex + 1;
        // Want to wrap back to the beginning here possibly?  Used to but it cause enter on password input to focus email input again.
        if (nextIndex < inputs.length) inputs[nextIndex].focus();
      }
    }
  }
  _forceLogouts(email) {
    if (window.location.origin == 'https://katapultpro.com' && email) {
      let domainsToLogOut = []; //['@davey.com'];
      domainsToLogOut.forEach((domain) => {
        if (email.endsWith(domain)) {
          this._signOut();
        }
      });
    }
  }
  _getBackgroundUrl() {
    let backgrounds = this.get('config.firebaseData.login_backgrounds');
    if (backgrounds) return backgrounds[Math.round(Math.random() * (backgrounds.length - 1))];
    return '';
  }
  _userGroupChanged() {
    // Setup listener to listen for changes to company name.
    const companyRef = FirebaseWorker.ref(`photoheight/companies/${this.userGroup}/name`);
    // Detach existing listener if present.
    if (this.companyCallback) companyRef.off('value', this.companyCallback);
    // Attach a new listener.
    this.companyCallback = FirebaseWorker.ref(`photoheight/companies/${this.userGroup}/name`).on('value', (s) => this._setCompany(s.val()));
    this._setKatapultSysAdmin();

    // Update the attribute event tracking parsers
    this.dispatchEvent(new CustomEvent('user-permissions-set'));
  }
  _reset(uid, token) {
    if (uid && token) {
      // Set change password form.
      this._showChangePasswordForm();
      // Make sure change password does not show password expired explanation.
      this.passwordExpired = false;
      // Remove reset password query parameters from url.
      let parsedUrl = new URL(window.location.href);
      let searchParams = new URLSearchParams(parsedUrl.search);
      searchParams.delete('f');
      searchParams.delete('u');
      searchParams.delete('t');
      parsedUrl.search = searchParams.toString();
      let newUrl = parsedUrl.toString();
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  }
  _sendPasswordResetEmail() {
    let resetOptions = { url: window.location.href };
    if (this.activeForm === 'resetPasswordForm') {
      // Clear errors.
      this._emailError = '';
      // Sanitize email.
      resetOptions.email = this.email = this._sanitizeEmail(this.email);
      // Validate email.
      resetOptions.error = this._emailError = this._validateEmail(this.email);
    } else if (this.activeForm === 'changePasswordForm') {
      // This is a request to resend an expired/invalid link.  Reset password for the account with the uid in _queryParameters.
      resetOptions.uid = decodeURIComponent(this.get('_queryParameters.u'));
      resetOptions.error = resetOptions.uid == null;
    }
    if (!resetOptions.error) {
      this._sendingResetPasswordEmail = true;
      firebase
        .functions()
        .httpsCallable('sendPasswordResetEmail')(resetOptions)
        .then(() => {
          this.email = this._password = '';
          this._passwordError = this._newPasswordError = this._confirmNewPasswordError = this._emailError = '';
          this._showSignInForm();
        })
        .catch((err) => {
          if (resetOptions.email) {
            // Reset Password Form.
            if (err.message == 'INTERNAL') this._emailError = 'An unexpected error has occurred. Please contact Katapult Pro Support';
            else if (err.message == 'sendPasswordResetEmail/mfa-required')
              this._emailError = 'Contact Katapult Pro Support to reset your password';
          } else if (resetOptions.uid) {
            // Change Password Form.
            let error = '';
            if (err.message == 'INTERNAL') error = 'An unexpected error has occurred. Please contact Katapult Pro Support';
            else if (err.message == 'sendPasswordResetEmail/mfa-required') {
              error = 'Contact Katapult Pro Support to reset your password';
            }
            if (this.passwordExpired) this._passwordError = error;
            else this._newPasswordError = error;
          }
          console.warn('Password Reset Error', err);
        })
        .finally(() => (this._sendingResetPasswordEmail = false));
    }
  }
  _sanitizeEmail(email) {
    email = (email || '').trim().toLowerCase();
    if (email.indexOf('@') == -1 && config.firebaseData.autocompleteEmail) email += config.firebaseData.autocompleteEmail;
    if (config.firebaseData.swapEmail) email = email.replace(config.firebaseData.swapEmail.from, config.firebaseData.swapEmail.to);
    return email;
  }
  _showChangePasswordForm() {
    this.activeForm = 'changePasswordForm';
  }
  _showMessageForm() {
    this.activeForm = 'messageForm';
  }
  _showResetPasswordForm() {
    this.activeForm = 'resetPasswordForm';
  }
  _showSignInForm() {
    this.activeForm = 'signInForm';
  }
  _openTermsOfService() {
    window.open('https://www.katapultengineering.com/terms-of-service');
  }
  _showSignUpForm() {
    if (this.signupLink != null) {
      window.location.href = this.signupLink;
    } else {
      this.activeForm = 'signUpForm';
    }
  }
  _onSignedIn() {
    // If email is missing from user record, update it.
    setTimeout(async () => {
      if (!this.user.email) {
        let email = await FirebaseWorker.ref(`user_info/${this.user.uid}/email`)
          .once('value')
          .then((s) => s.val());
        if (email) this.user.updateEmail(email);
      }
    });
  }
  _signedInChanged(signedIn) {
    if (signedIn) {
      this._showSignInForm();
      this._showMfaCode = false;

      // Clear alternate sign in error messages
      this.googleSignInError = this.microsoftSignInError = null;

      // Clear query parameters.
      this.set('_queryParameters.f', null);
      this.set('_queryParameters.u', null);
      this.set('_queryParameters.t', null);
      this.dispatchEvent(new CustomEvent('sign-in'));
    }
  }
  _signIn(e) {
    // TODO: We need to change this function to use a new AuthHelpers function to see if the user is allowed to sign in using email/password based on the user's root company settings

    // only sign in if we are online
    if (navigator.onLine) {
      // Clear errors.
      this._emailError = this._passwordError = '';
      // Sanitize email.
      this.email = this._sanitizeEmail(this.email);
      // Validate email.
      this._emailError = this._validateEmail(this.email);
      // Ensure password has been entered.
      if (!this._password) this._passwordError = 'Password Required';
      if (!this._emailError && !this._passwordError) {
        this._passwordVisible = false;
        this._signingIn = true;
        firebase
          .functions()
          .httpsCallable('auth_v2')({ email: this.email, password: this._password, url: window.location.href })
          .then((res) => {
            if (res.data.mfa) {
              this._mfaCodeUid = res.data.uid;
              this._signingIn = false;
              this._showMfaCode = true;
            } else if (res.data.token) {
              this.passwordExpired = res.data.passwordExpired;
              if (this.passwordExpired) {
                // Store token for authenticating in a moment once password is reset.
                this._token = res.data.token;
                this._signingIn = false;
                this._showChangePasswordForm();
              } else {
                // Go ahead and authenticate.
                this.$.auth.signInWithCustomToken(res.data.token).then(() => {
                  this.email = this._password = '';
                  this._signingIn = false;
                  this._onSignedIn();
                  this._closeSignIn();
                });
              }
            }
          })
          .catch((err) => {
            if (err.message == 'auth/katapult-inactive-user') {
              this._passwordError = 'Your account is not active. Please contact Katapult for assistance.';
            } else if (err.message == 'auth/invalid-auth') {
              this._passwordError = 'Your email or password is incorrect, or you are using the wrong login method.';
            } else if (err.message == 'auth/password-must-reset') {
              this.resetPasswordText = 'Reset Password';
              this._passwordError = 'Your password has expired. Please reset it below to sign in.';
            } else if (err.message == 'auth/update-password-requirements') {
              this._passwordError = 'Our password requirements have changed. Please reset your password below.';
              this._signingIn = false;
              this._showChangePasswordForm();
            } else if (err.message == 'auth/missing-auth' || err.message == 'auth/missing-user-group') {
              this._emailError = 'Something is wrong with your account. Please contact Katapult Pro Support';
            } else if (err.message == 'auth/account-locked') {
              this._emailError = `Your account has been locked due to too many failed login attempts.  Please try again in ${
                HowLong(err.details.accountLockedUntil - Date.now())[0]
              }.`;
            } else if ((err.message || '').toLowerCase() == 'internal') {
              if (navigator.onLine) this._emailError = 'An unexpected error has occurred. Please contact Katapult Pro Support';
              else this._emailError = 'You are currently offline and unable to sign in. Please try again once you are online';
            } else {
              this._emailError = err.message;
            }
            console.warn('Sign In', err, err.message);
            this._signingIn = false;
          });
      }
    }
  }
  _signOut() {
    this.$.auth.signOut();
  }
  _statusKnownChanged() {
    // Once we know if we are logged in or not, wait a second and then set overlay to animate.
    setTimeout(() => (this.$.overlay.style.transition = 'opacity 0.4s'), 1000);
  }
  _systemAdminDataChanged(e) {
    this._setSystemAdmin(e.detail === true ? true : false);
    this._setKatapultSysAdmin();
  }
  _goBackToMainSignInForm() {
    this._emailError = '';
    // if there is a previous active form, go back to it
    if (this._previousActiveForm) {
      this.activeForm = this._previousActiveForm;
      this._previousActiveForm = null;
    }
    this.enterpriseSSOSelected = false;
  }
  _calcShowAlternateSignInMethods(googleSignInEnabled, microsoftSignInEnabled, enterpriseSSOEnabled, enterpriseSSOSelected) {
    return (googleSignInEnabled || microsoftSignInEnabled || enterpriseSSOEnabled) && !enterpriseSSOSelected;
  }
  async _continueWithEnterpriseSSO() {
    this._signingIn = true;
    // Clear errors.
    this._emailError = this._passwordError = '';
    // Sanitize email.
    this.email = this._sanitizeEmail(this.email);
    // Validate email.
    this._emailError = this._validateEmail(this.email);
    if (this._emailError) {
      this._signingIn = false;
      return;
    }

    // get the user's providerId from the given email
    let providerId = null;
    await firebase
      .functions()
      .httpsCallable('getProviderFromEmail')({ email: this.email })
      .then((res) => {
        providerId = res.data.providerId;
      })
      .catch((error) => {
        if (error.message == 'auth/invalid-auth') {
          this._emailError =
            'Enterprise SSO is not enabled for this email. If you suspect this is an error, please contact Katapult Pro Support';
        } else if ((error.message || '').toLowerCase() == 'internal') {
          if (navigator.onLine) this._emailError = 'An unexpected error has occurred. Please contact Katapult Pro Support';
          else this._emailError = 'You are currently offline and unable to sign in. Please try again once you are online';
        } else {
          this._emailError = error.message;
        }
        this._signingIn = false;
        console.log(error);
        throw new Error(`Error retrieving provider id from email`);
      });

    // sign the user in with the given provider
    this._signInWithProvider(providerId, true);
  }
  _handleSsoButtonClicked(e) {
    // if this button was clicked from the change password form, make sure the user has agreed to the EULA
    if (this.activeForm === 'changePasswordForm' && !this.eulaAgreed) {
      this._confirmNewPasswordError = 'Please accept the EULA';
      return;
    }
    this._confirmNewPasswordError = '';

    let providerId = e.currentTarget.dataset.providerId;
    if (!providerId) return;
    this._signInWithProvider(providerId);
  }
  async chromeExtensionSignInWithPopup(providerId) {
    if (!this.isChromeExtension) throw Error('This function can only be called from a Chrome Extension');
    const { error, credential: credentialJSON } = await new Promise(async (resolve, reject) => {
      try {
        await globalThis.chrome.runtime.sendMessage(
          { target: 'background', action: 'sign-in-with-popup', appId: window.config.appName, providerId },
          resolve
        );
      } catch (err) {
        reject(err);
      }
    });
    if (error) throw Error(error);
    const providerIsSAML = providerId.startsWith('saml.');
    const providerClass = providerIsSAML ? firebase.auth.SAMLAuthProvider : firebase.auth.OAuthProvider;
    const credential = providerClass.credentialFromJSON(credentialJSON);
    await firebase.auth().signInWithCredential(credential);
  }
  // Sign the user in using the given provider
  _signInWithProvider(providerId, samlProvider) {
    // clear email and password
    this.email = this._password = '';
    // Clear errors.
    this._emailError = this._passwordError = '';

    // based on the providerId, determine which spinner and error variables to use
    let providerName = null;
    let signInSpinnerVar = null;
    let signInErrorVar = null;
    if (samlProvider) {
      providerName = 'Enterprise SSO';
      signInSpinnerVar = '_signingIn';
      signInErrorVar = '_emailError';
    } else {
      switch (providerId) {
        case 'google.com':
          providerName = 'Google';
          signInSpinnerVar = '_signingInGoogle';
          signInErrorVar = 'googleSignInError';
          break;
        case 'microsoft.com':
          providerName = 'Microsoft';
          signInSpinnerVar = '_signingInMicrosoft';
          signInErrorVar = 'microsoftSignInError';
          break;
        default:
          throw new Error(`Failed to sign in. Unknown providerId: ${providerId}`);
      }
    }

    // Set the spinner for the provider button
    this[signInSpinnerVar] = true;

    // Create an instance of the provider object
    let provider = null;
    if (samlProvider) {
      provider = new firebase.auth.SAMLAuthProvider(providerId);
    } else {
      provider = new firebase.auth.OAuthProvider(providerId);
    }

    // sign in with a redirect if:
    // - the user is on /mobile
    // - the user is not using safari
    // - the user is not on a dev origin
    // - the user is not on beta
    if (self.isMobile && !self.browserInfo.safari && !self.originIsDev && !self.location.origin.includes('beta.katapultpro.com')) {
      firebase.auth().signInWithRedirect(provider);
    } else {
      // Allow the user to sign in via the provider popup.
      const authPromise = this.isChromeExtension
        ? this.chromeExtensionSignInWithPopup(providerId)
        : firebase.auth().signInWithPopup(provider);
      authPromise
        .then((result) => {
          // Reset the sign-in spinner
          this[signInSpinnerVar] = false;
        })
        .catch((error) => {
          console.log(error);
          if (error.message?.includes('auth/invalid-auth')) {
            this[signInErrorVar] = `${providerName} sign-in is not enabled for this email`;
          } else if (error.message?.includes('Cloud function deadline exceeded.')) {
            // If the blocking function is performing a cold start, it'll take longer than the maximum 7 seconds to respond.
            // The user can likely log in if they try again after the function has warmed up.
            this[signInErrorVar] = 'Something went wrong, please try again in a moment';
          } else {
            // dont show an error if the user closed the popup
            if (error.code !== 'auth/popup-closed-by-user') {
              this[signInErrorVar] = error.code;
            } else {
              this[signInErrorVar] = null;
            }
          }
          // Reset the sign-in spinner
          this[signInSpinnerVar] = false;
          throw new Error(`Error signing in with ${providerName}: ${error.message}`);
        });
    }
  }
  _enterpriseSSOSelected() {
    // if this button was clicked from the change password form, make sure the user has agreed to the EULA
    if (this.activeForm === 'changePasswordForm' && !this.eulaAgreed) {
      this._confirmNewPasswordError = 'Please accept the EULA';
      return;
    }
    this._confirmNewPasswordError = '';

    // store the previous active form
    this._previousActiveForm = this.activeForm;
    // set the active form to the sign in form
    this.activeForm = 'signInForm';
    this.enterpriseSSOSelected = true;
  }
  _togglePasswordVisible() {
    this._passwordVisible = !this._passwordVisible;
  }
  _updateSentryUserRecord() {
    // If user is undefined, clear Sentry's user record
    if (!this.user) return SentrySetUser(null);

    // Set Sentry's user record (user_group may be undefined)
    SentrySetUser({
      id: this.user.uid,
      email: this.user.email,
      user_group: this.userGroup
    });
  }
  _updateFeatureFlagsContext() {
    // Also update the user info for IPL Version Manager
    IPLVersionManagerSetUserInfo(this.user?.uid, this.userGroup);
    // If user is undefined, clear the feature flags context
    if (!this.user) return FeatureFlagsUpdateContext(null);

    // Set the feature flags context
    return FeatureFlagsUpdateContext({
      companyId: this.userGroup,
      userId: this.user.uid
    });
  }
  _urlMfa(uid, token) {
    if (decodeURIComponent(this.get('_queryParameters.f')) === 'mfa' && uid && token) {
      // Set mfa form.
      this.activeForm = 'linkForm';
      // Clear mfa error.
      this._linkError = '';
      // Set mfa message.
      this._linkMessage = 'Authenticating...';
      // Set signing in flag.
      this._signingIn = true;
      // Remove MFA query parameters from url.
      let parsedUrl = new URL(window.location.href);
      let searchParams = new URLSearchParams(parsedUrl.search);
      searchParams.delete('f');
      searchParams.delete('u');
      searchParams.delete('t');
      parsedUrl.search = searchParams.toString();
      let newUrl = parsedUrl.toString();
      window.history.pushState({ path: newUrl }, '', newUrl);
      // Decode uid & token.
      uid = decodeURIComponent(uid);
      token = decodeURIComponent(token);
      // Perform actual auth.
      firebase
        .functions()
        .httpsCallable('auth_v2')({ uid, token })
        .then((res) => {
          if (res.data.token) {
            this._linkMessage = 'Successfully Authenticated';
            setTimeout(() => {
              this.passwordExpired = res.data.passwordExpired;
              if (this.passwordExpired) {
                this.email = res.data.email;
                this._token = res.data.token;
                setTimeout(() => {
                  this._signingIn = false;
                  this._showChangePasswordForm();
                }, 1000);
              } else {
                this.$.auth.signInWithCustomToken(res.data.token).then(() => {
                  this.email = this._password = '';
                  this._signingIn = false;
                  this._onSignedIn();
                  setTimeout(() => {
                    if (this.activeForm != 'forbidden') this._showSignInForm();
                  }, 400);
                });
              }
            }, 1000);
          }
        })
        .catch((err) => {
          if (err.message == 'auth/mfa-token-expired') {
            this._linkError = 'This link has expired';
          } else if (err.message == 'auth/invalid-token') {
            this._linkError = 'This link is invalid';
          } else this._linkError = 'An unexpected error has occurred. Please contact Katapult Pro Support';
          this._signingIn = false;
        });
    }
  }
  _urlReset(uid, token) {
    if (decodeURIComponent(this.get('_queryParameters.f')) === 'reset') this._reset(uid, token);
  }
  _userChanged() {
    this._loadUserData = this.get('user.uid') != null;
  }
  async _userPermissionsChanged() {
    // console.log('GOT USER PERMISSIONS:', this._userPermissions);
    // Force refresh on the token to pick up the latest custom claims changes
    await firebase.auth().currentUser?.getIdToken(true);
    // Set user group.
    this._setUserGroup(this.get('_userPermissions.permissions.photoheight'));
    this._setRootCompany(this.get('_userPermissions.root_company') || this.userGroup);
    // Set user permission flags if we receied user permissions
    if (this._userPermissions) {
      this._setReadOnly(this.get(`_userPermissions.individual_permissions.${this.userGroup}.write`) !== true);
      this._setCanWrite(this.get(`_userPermissions.individual_permissions.${this.userGroup}.write`) === true);
      this._setCanRead(this.get(`_userPermissions.individual_permissions.${this.userGroup}.read`) !== false);
      this.dispatchEvent(new CustomEvent('user-permissions-set'));
    } else {
      // otherwise set all the flags to false
      this._setReadOnly(false);
      this._setCanWrite(false);
      this._setCanRead(false);
    }
  }
  _userRecordChanged() {
    this._setPendingPermission(this.userRecord?.pending_permission);
  }
  _validateEmail(email) {
    // Ensure email is valid.
    let atpos = email.indexOf('@');
    let dotpos = email.lastIndexOf('.');
    if (!email) return 'Email Required';
    else if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= email.length) return 'Invalid Email Address';
    // else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) return 'Invalid Email Address';
    return '';
  }
  // following this GitHub link to alleviate the issue where the overlay would display above the paper-dialog
  // https://github.com/PolymerElements/paper-dialog/issues/7#issuecomment-266285207
  patchOverlay(e) {
    if (e.target.withBackdrop) {
      e.target.parentNode.insertBefore(e.target.backdropElement, e.target);
    }
  }
  _setKatapultSysAdmin() {
    this.katapultSysAdmin = this.systemAdmin && this.userGroup == 'katapult';
    if (this.katapultSysAdmin && this.showAuthContent) {
      KatapultBanner.createBanner('system-admin-warning', {
        backgroundColor: 'red',
        textColor: 'white',
        body: 'YOU ARE A SUPER ADMIN'
      });
    } else {
      KatapultBanner.destroyBanner('system-admin-warning');
    }
  }
}
window.customElements.define(KatapultAuth.is, KatapultAuth);
