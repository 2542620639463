import { LitElement, html, css, nothing } from 'lit';
import '../katapult-elements/katapult-button.js';

class KatapultStatusNotifier extends LitElement {
  static styles = css`
    #wrapper {
      position: fixed;
      top: 80px;
      left: 0px;
      right: 0px;
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1000; /* ugh */
    }
    #notification {
      box-sizing: border-box;
      padding: 10px;
      pointer-events: auto;
      width: 80vw;
      max-width: 600px;
      display: grid;
      grid-template-columns: 1fr auto;
      border-radius: 20px;
      box-shadow: rgba(0, 0, 0, 0.5) 0px 15px 15px -5px;
    }
    #notification.info {
      background: #003e51;
      color: white;
    }
    #notification.warning {
      background: #ff8300;
      color: white;
    }
    #left {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    #message {
      font-size: larger;
    }
    a {
      color: white;
    }
  `;

  constructor() {
    super();
    // Assume clean state to start
    this.status = 'ok';
    // Define statuses where we display the notification
    this.displayStatuses = ['info', 'warning'];
    // Default to dismissed to false so we show the notification, if necessary
    this.dismissed = false;
    // We haven't checked the status yet
    this.lastCheckTime = 0;
    // Set check cooldown interval in ms
    this.minCheckInterval = 120000;
    // Location of the status JSON
    this.statusURL = 'https://KatapultDevelopment.github.io/katapult-pro-status/status.json';
    // Create a place to store a timeout, because we only want 1.
    this.timeout;

    // Do our first status check
    this.checkStatus();

    // Set up an error listener, so that if the client encounters an error, we can recheck the status.
    // Unclear if we would get errors in a database lockup situation,
    // but it seems probable that lots of things will likely break when the DB is unavailable, which could throw errors
    window.onerror = (err) => {
      this.checkStatus();
    };
  }

  /**
   * Creates a pseudo-random string of specified length
   * @param {number} length - size of requested string
   * @returns result - a string of length random characters
   */
  createRandomString(length) {
    // Set up the character parameters for random string generation
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;
    let result = '';
    while (result.length < length) {
      // Find a random character from characters and add it to result
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  /**
   * Checks for the status as long as certain criteria are met & updates the view accordingly
   * @param {boolean} isTimeout - whether the function is being called from a setTimeout();
   */
  checkStatus(isTimeout = false) {
    // Do nothing UNLESS:
    // We're checking because of a status JSON defined refresh interval
    // OR
    // the last check was less than this.minCheckInterval ago
    const now = new Date();
    if (isTimeout || now - this.lastCheckTime > this.minCheckInterval) {
      // Set the time of the current status check;
      this.lastCheckTime = now;
      // Get the status JSON & Add a random string to the query for cache-busting the CDN
      fetch(`${this.statusURL}?${this.createRandomString(8)}`)
        .then((res) => res.json())
        .then((data) => {
          if (data?.status === 'ok') {
            // The notification won't display if status == 'ok'
            this.status = 'ok';
            // if the status exists but is not 'ok', evaluate if we should display a message
          } else if (data?.status != null) {
            // if the status or other info is different from last time, update and redisplay the message
            if (!(data.status === this.status && data.message === this.statusData?.message && data.url === this.statusData?.url)) {
              this.statusData = data;
              this.status = data.status;
              this.dismissed = false;
            }
            // if there is a refresh attribute, its a number, and its more than 0.  set a timeout for a refresh
            if (typeof data?.refresh === 'number' && data.refresh > 0) {
              // clear the timeout so we don't end up with multiple timeouts
              clearTimeout(this.timeout);
              this.timeout = setTimeout(() => {
                this.checkStatus(true);
              }, data.refresh);
            }
          }
          this.update();
        })
        .catch((err) => {
          console.error('There was an error fetching the system status.', err);
        });
    }
  }

  render() {
    return html`
      <!-- Only show the notification if the status is in the list of bad statuses & user has not dismissed the notification -->
      ${this.displayStatuses.includes(this.status) && this.dismissed === false
        ? html`
            <div id="wrapper">
              <div id="notification" class="${this.status}">
                <div id="left">
                  ${this.statusData?.message == null ? '' : html`<div id="message">${this.statusData.message}</div>`}
                  ${this.statusData?.url == null
                    ? ''
                    : html`<a id="url" href="${this.statusData.url}" target=${this.statusData?.urlNewTab ? '_blank' : nothing}
                        >${this.statusData?.urlText ? this.statusData.urlText : this.statusData?.url}</a
                      >`}
                </div>
                <katapult-button @click="${(e) => this.dismiss(e)}" style="color:white">Dismiss</katapult-button>
              </div>
            </div>
          `
        : html``}
    `;
  }

  /**
   * Dismisses the notification and updates the view
   * @param {object} e - The event object.  We don't actually need it.
   */
  dismiss(e) {
    this.dismissed = true;
    this.update();
  }
}

customElements.define('katapult-status-notifier', KatapultStatusNotifier);
