/** Converts date from ms to Date Format */
export function FormatDate(dateInMS, dateFormat) {
  dateFormat = dateFormat || { day: '2-digit', year: 'numeric', month: '2-digit' };
  let timestamp = new Date(parseInt(dateInMS));
  return timestamp.toLocaleDateString('en-US', dateFormat);
}
export function Pad(x, n) {
  let temp = (x || '').toString();
  while (temp.length < n) temp = '0' + temp;
  return temp;
}
export function DaysBetween(a, b) {
  a = new Date(a);
  b = new Date(b);
  a.setHours(0, 0, 0, 0);
  b.setHours(0, 0, 0, 0);
  return Math.floor((a.getTime() - b.getTime()) / 86400000);
}
export function HowLong(time) {
  let remaining = time;
  let parts = [];
  let millis = {
    day: 86400000,
    hour: 3600000,
    minute: 60000,
    second: 1000
  };
  Object.keys(millis).forEach((key, i, arr) => {
    let n = Math.floor(remaining / millis[key]);
    if (parts.length || n > 0 || i == arr.length - 1) {
      remaining -= n * millis[key];
      parts.push(`${n} ${key + (n - 1 ? 's' : '')}`);
    }
  });
  return parts;
}
