import { KatapultElement, html } from '../../mixins/katapult-element.js';
class MaterialIcon extends KatapultElement {
  static get template() {
    return html`
      <style>
        :host {
          display: flex;
          font-size: 24px; /* Preferred icon size */
          user-select: none;
          -webkit-user-select: none; /* Safari */
        }
        .material-icons {
          font-family: 'Material Icons';
          font-weight: normal;
          font-style: normal;
          font-size: inerhit;
          display: inline-block;
          /* line-height: 1; */
          text-transform: none;
          letter-spacing: normal;
          word-wrap: normal;
          white-space: nowrap;
          direction: ltr;

          /* Support for all WebKit browsers. */
          -webkit-font-smoothing: antialiased;
          /* Support for Safari and Chrome. */
          text-rendering: optimizeLegibility;

          /* Support for Firefox. */
          -moz-osx-font-smoothing: grayscale;

          /* Support for IE. */
          font-feature-settings: 'liga';
        }
        /* Recommended sizes. */
        .material-icons[icon-size='md-16'] {
          font-size: 16px;
        }
        .material-icons[icon-size='md-18'] {
          font-size: 18px;
        }
        .material-icons[icon-size='md-24'] {
          font-size: 24px;
        }
        .material-icons[icon-size='md-36'] {
          font-size: 36px;
        }
        .material-icons[icon-size='md-48'] {
          font-size: 48px;
        }
      </style>
      <span class="material-icons" icon-size$="[[_getSizeClass(size)]]">[[icon]]</span>
    `;
  }
  static get properties() {
    return {
      icon: String,
      size: String
    };
  }

  _getSizeClass(sizeDescriptor) {
    switch (sizeDescriptor) {
      case 'micro':
        return 'md-16';
      case 'small':
        return 'md-18';
      case 'large':
        return 'md-36';
      case 'xlarge':
        return 'md-48';
      default:
        'md-24';
    }
  }
}

window.customElements.define('material-icon', MaterialIcon);
