import { LitElement, html, nothing, css, render } from 'lit';
import '../material-icon/material-icon.js';

/**
 * @typedef {Object} BannerConfig
 * @property {String} [backgroundColor=var(--secondary-color)] - The background color of the banner
 * @property {String} [textColor=var(--secondary-color-text-color)] - The color for text in the banner
 * @property {String} [icon=''] - A material icon to display in the banner
 * @property {String} [title=''] - The text to display for the title of the banner
 * @property {String} [body=''] - The text to display for the body of the banner
 * @property {Boolean} [dismissible=false] - Whether or not the banner can be dismissed
 * @property {Boolean} [cacheDismiss=false] - Whether or not the banner caches the dismissed state
 */

/**
 * @property {Array<BannerConfig>} _banners - A lookup of banner elements by their id
 */
const _banners = {};
/**
 * @property {HTMLElement} _bannerContainer - The HTML element to render banners into
 */
let _bannerContainer = null;

export class KatapultBanner extends LitElement {
  static properties = {
    backgroundColor: { type: String },
    textColor: { type: String },
    icon: { type: String },
    title: { type: String },
    body: { type: String },
    dismissible: { type: Boolean },
    _bannerDismissed: { type: Boolean, state: true }
  };

  static styles = css`
    .banner {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 16px;
    }
    .bannerIcon {
      margin-right: 8px;
    }
    .bannerTitle {
      margin-right: 8px;
      font-weight: bold;
    }
    .bannerSpacer {
      flex-grow: 1;
    }
    .bannerDismissIcon {
      flex-grow: 0;
    }
    [pointer]:hover {
      cursor: pointer;
    }
    a {
      color: inherit;
    }
  `;

  /**
   * Creates a new banner element
   * @param {BannerConfig?} bannerConfig - the configuration for the banner
   * @param {String?} bannerId - an id of the banner to use to uniquely identify that banner
   */
  constructor(bannerConfig, bannerId) {
    super();

    this.backgroundColor = bannerConfig?.backgroundColor ?? 'var(--secondary-color)';
    this.textColor = bannerConfig?.textColor ?? 'var(--secondary-color-text-color)';
    this.icon = bannerConfig?.icon ?? '';
    this.title = bannerConfig?.title ?? '';
    this.body = bannerConfig?.body ?? '';
    this.dismissible = bannerConfig?.dismissible ?? false;
    this.cacheDismiss = bannerConfig?.cacheDismiss ?? false;

    this._bannerId = bannerId;
    // Set initial dismissed state based on a variety of factors
    this._bannerDismissed = this.#shouldDismissBanner();
  }

  render() {
    const { backgroundColor, textColor, icon, title, body, dismissible, _bannerDismissed } = this;

    const bodyTemplate = this._renderBody(body);

    return html`
      ${_bannerDismissed
        ? nothing
        : html`<div class="banner" style="background: ${backgroundColor}; color: ${textColor};">
            <div class="bannerSpacer"></div>
            ${icon ? html`<material-icon class="bannerIcon" icon="${icon}"></material-icon>` : nothing}
            ${title ? html`<div class="bannerTitle">${title}</div>` : nothing} ${bodyTemplate}
            <div class="bannerSpacer"></div>
            ${dismissible
              ? html`<material-icon class="bannerDismissIcon" icon="clear" pointer @click="${this.#dismissBannerClicked}"></material-icon>`
              : nothing}
          </div>`}
    `;
  }

  _renderBody(body) {
    if (!body) return nothing;

    const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    const parts = [];

    const getIndexOfMatchEnd = (match) => (match != null ? match.index + match[0].length : 0);

    let match, prevMatch;
    while ((match = markdownLinkRegex.exec(body))) {
      const precedingText = body.slice(getIndexOfMatchEnd(prevMatch), match.index);
      if (precedingText != '') parts.push(html`<span>${precedingText}</span>`);
      const [, linkText, linkUrl] = match;
      const link = html`<a href="${linkUrl}">${linkText}</a>`;
      parts.push(link);
      prevMatch = match;
    }
    const trailingText = body.slice(getIndexOfMatchEnd(prevMatch));
    if (trailingText != '') parts.push(html`<span>${trailingText}</span>`);

    return html`<div>${parts}</div>`;
  }

  /**
   * Called when the user clicks on the dismiss icon. Dismisses the banner and caches the dismiss if allowed.
   */
  #dismissBannerClicked() {
    // Dismiss the banner
    this._bannerDismissed = true;
    // Store the decision to dismiss the banner in local storage if the option for cache dismiss is true
    if (this.cacheDismiss) {
      localStorage?.setItem(KatapultBanner.#getBannerCachePath(this._bannerId), 'true');
    }
  }

  /**
   * Checks if the banner should be dismissed based on the configuration and cache
   * @returns {Boolean} - Whether or not the banner should be dismissed
   */
  #shouldDismissBanner() {
    // If the dialog does not have an id, don't check the cache because it's a static element
    if (!this._bannerId) return false;

    // Get the path in the cache
    const bannerCachePath = KatapultBanner.#getBannerCachePath(this._bannerId);

    // If we should not cache the dismiss for this banner, then remove the storage location for hiding the banner and show it
    if (!this.cacheDismiss) {
      localStorage?.removeItem(bannerCachePath);
    }

    // If the banner is not dismissible, don't dismiss it
    if (!this.dismissible) return false;

    // If the configuration allows for caching, check the cache
    const dismissWasCached = localStorage?.getItem(bannerCachePath) == 'true';
    return this.cacheDismiss && dismissWasCached;
  }

  /**
   * Returns the key used in local storage to cache the dismissed state of a banner
   * @param {String} bannerId - the id of the banner to get the cache path for
   * @returns {String} - the path in the local cache
   */
  static #getBannerCachePath(bannerId) {
    if (!bannerId) throw new Error('Cannot get banner cache path without banner id');
    return `katapult-banner-dismiss-${bannerId}`;
  }

  /**
   * Renders all of the banners into the banner container
   */
  static #renderBanners() {
    if (!_bannerContainer) {
      console.warn('No banner container registered. Banners will not render.');
      return;
    }
    render(Object.values(_banners), _bannerContainer);
  }

  /**
   * Creates a new KatapultBanner element, adds it to the lookup, and then renders all banners
   * @param {String} bannerId - the id of the banner to use
   * @param {BannerConfig} bannerConfig - the configuration for the banner
   */
  static createBanner(bannerId, bannerConfig) {
    const banner = new KatapultBanner(bannerConfig, bannerId);
    _banners[bannerId] = banner;

    this.#renderBanners();
  }

  /**
   * Removes the banner for the given id from the lookup, clears any cached
   * paths, and then renders all banners
   * @param {String} bannerId - the id of the banner to destroy
   */
  static destroyBanner(bannerId) {
    delete _banners[bannerId];
    // Remove banner cache
    const bannerCachePath = KatapultBanner.#getBannerCachePath(bannerId);
    localStorage?.removeItem(bannerCachePath);
    this.#renderBanners();
  }

  /**
   * Assigns an HTML element as the container to render banners in
   * @param {HTMLElement} container - the element to use as the container
   */
  static registerBannerContainer(container) {
    _bannerContainer = container;
  }
}

customElements.define('katapult-banner', KatapultBanner);
